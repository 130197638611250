import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaEnvelope, FaGithub} from 'react-icons/fa'
import {GrInstagram} from 'react-icons/gr'
import {FaFacebook} from "react-icons/fa";

const Headersocials = () => {
  return (
    <div className="header__socials">
        <a href="mailto:priyanshukhanda@gmail.com" target='_blank' rel='noreferrer'><FaEnvelope/></a>
        <a href="https://www.instagram.com/priyanshukhanda/" target='_blank' rel='noreferrer'><GrInstagram /></a>
        <a href="https://www.linkedin.com/in/priyanshu-khanda-097610211/" target='_blank' rel='noreferrer'><BsLinkedin/></a>
        <a href="https://github.com/pri-k" target='_blank' rel='noreferrer'><FaGithub/></a>
        <a href="https://www.facebook.com/priyanshukhanda" target='_blank' rel='noreferrer'><FaFacebook/></a>
    </div>
  )
}

export default Headersocials