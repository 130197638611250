import React from 'react';
import './experience.css';
import {BsPatchCheckFill} from 'react-icons/bs';
// import { Navigation, Pagination } from 'swiper/modules';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

const Experience = () => {
  return (
    <section id='experience'>
      <h4>What skills I have</h4>
      <h2>My Skills</h2>

      <div className='container experience__container'>
        <div className="experience__frontend">
          <h3>Programming Languages</h3>
          <div className="experience__content">
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>C++</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>C</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>Python</h4>
                </div>
            </article>
            
          </div>
          <br></br>
          <h3>WebDev Languages/Frameworks</h3>
          <div className="experience__content">
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>HTML</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>Css</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>JavaScript</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>ReactJs</h4>
                </div>
            </article>
            
          </div>
        </div>
        <div className="experience__backend">
        <h3>Adobe Suite</h3>
          <div className="experience__content">
          <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>Photoshop</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>Illustrator</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>Premiere Pro</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>After Effects</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>Lightroom</h4>
                </div>
            </article>
          </div>
          <h3>Miscellaneous</h3>
          <div className="experience__content">
          <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>MySQL</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>LaTeX</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>Cisco packet tracer</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>MATLAB</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>LTSpice/Pspice</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>AutoCad</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>EagleCad</h4>
                </div>
            </article>
            <article className='experience__details'>
                <BsPatchCheckFill className='experienced__details-icon'/>
                <div>
                <h4>Git</h4>
                </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Experience;