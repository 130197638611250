import React from 'react';
import './header.css';
import CTA from './cta';
import ME from '../../assets/me.png';
import Headersocials from './headersocials';
import Typed from "typed.js";
import { useEffect, useRef } from "react";

const Header = () => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Web Developer", "Graphic Designer", "Content Writer", "Student", "Learner","Programmer"],
      startDelay: 500,
      typeSpeed: 120,
      backSpeed: 120,
      backDelay: 50,
      loop:true
    });
    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <header id=''>
      <div className="container header__container">
        <h3>Hello, I'm</h3>
        <h1>Priyanshu Khanda</h1>
        {/* <h5 className="text-light">Frontend Developer</h5> */}
        <span ref={el} className='changing__text'></span>
        <CTA/>
        <Headersocials/>
        <div className="me">
          <img src={ME} alt="me" className='header-img-me'/>
        </div>
        <a href='#about' className='scroll__down'>Scroll Down</a>
        
      </div>
    </header>
  )
};

export default Header;