import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';
import {MdOutlineEmail} from 'react-icons/md';

const Contact = () => {
  const form=useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_nczcmvu', 'template_ddyapjl', form.current,'wfCbIcEZ8IgjFxwH5');
      e.target.reset();
  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact_container">
        <div className="contact_options">
          <article className="contact_option">
            <MdOutlineEmail className='contact_option-icon'/>
            <h4>Whatsapp/Contact</h4>
            <h5>+91 99103-90001</h5>
            <a href="http://wa.me/+919910390001">Text/Call</a>
          </article>
          <article className="contact_option">
            <MdOutlineEmail className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>priyanshukhanda@gmail.com</h5>
            <a href="mailto: priyanshukhanda@gmail.com">Send a message </a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
};

export default Contact;