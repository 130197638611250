import React from 'react';
import './about.css';
import ME from '../../assets/me-about.jpg';
import {FaAward} from 'react-icons/fa'

const About = () => {
  return (
    <section id='about'>
       <h4>Get to know</h4>
       <h2>About Me</h2>

       <div className="container about__container">
            <div className="about__me">
                <div className="about__me-image">
                    <img src={ME} alt='me'/>
                </div>
            </div>
            <div className="about__content">
                <div className="about__cards">
                    <article className='about__card'>
                        <FaAward className='about__icon'/>
                        <h5>Education</h5>
                        <small>12th Class - <a href="https://vishalbhartipublicschool.in/" target='_blank'>VBPS</a></small>
                    </article>
                    <article className='about__card'>
                        <FaAward className='about__icon'/>
                        <h5>Education</h5>
                        <small>B.Tech(ECE) - <a href="http://nsut.ac.in/en/home" target='_blank'>NSUT</a></small>
                    </article>
                    <article className='about__card'>
                        <FaAward className='about__icon'/>
                        <h5>Experience</h5>
                        <small>2 years working</small>
                    </article>
                </div>
                <p>
                {/* From my fascination with computers and coding that sparked in my early teens, I embarked on a journey of exploration and learning new frontiers of technology. With a B.Tech in ECE (ongoing) and a diverse skill set, I am well-equipped to take on any challenge. 
                Driven by my passion, I've honed my creative abilities, actively participated in hackathons, and qualified in prestigious coding competitions.  */}
                From my fascination with computers and coding that sparked in my early teens, I embarked on a journey of exploration and learning new frontiers of technology. With a B.Tech in ECE (ongoing) and a strong foundation in programming and web development, I thrive on creating innovative solutions and enhancing user experiences. Additionally, I am proficient in Adobe suite which I leverage to create compelling graphic designs and video edits. Thank you for visiting, and I look forward to connecting with you!
                {/* My experience includes contributing to projects like the <a href='https://junoonnsut.com/' target='_blank' rel="noreferrer">Junoon website</a>, developing a Text to Braille Converter, <a href='https://organised.netlify.com/' target='_blank' rel="noreferrer">Task Organiser</a>,etc.<br/> */}
                </p>
                <a href='#contact' className='btn btn-primary lt'>Let's Talk</a>
            </div>
       </div>
       
    </section>
  )
};

export default About;