import React from 'react';
import AVTR1 from '../../assets/avatar1.jpg';
import AVTR2 from '../../assets/avatar2.jpg';
import AVTR3 from '../../assets/avatar3.jpg';
import './testimonials.css';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const data=[
  {avatar:AVTR1,
  name:'abcd',
  review: 'lorema smdosdmsmda dsdmsdmismda s djmsimd kkkkkkkkkkkkkkkkkkkkkkkkkkkkk kkkkkkkkkkkkkkkk kkkkkkkkkkkkkkkkkk k kkkkkkkkkkkkkkkkkkkkkkk kk                 k k k kk k k kk k k                  k k kkkkkkkkkkkkkkkkk '},
  {avatar:AVTR2,
  name:'abcd',
  review: 'lorema smdosdmsmda dsdmsdmismda s djmsimd'},
  {avatar:AVTR3,
  name:'abcd',
  review: 'lorema smdosdmsmda dsdmsdmismda s djmsimd'},
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials </h2>
      <Swiper className="container testimonials_container"
       modules={[Navigation, Pagination]}
       spaceBetween={40}
       slidesPerView={1}
       navigation
       pagination={{ clickable: true }}>
        {
          data.map(({avatar,name,review},index)=>{
            return(
            <SwiperSlide key={index} className="testimonial">
            <div className="client_avatar">
              <img src={avatar} alt="Avatar One" />
            </div>
            <h5 className='client_name'>{name} </h5>
              <small className='client_review'>
              {review}
              </small>
            </SwiperSlide>
          )})
        }
      </Swiper>
    </section>
  )
};

export default Testimonials;