import React from 'react';
import './footer.css';
import {FaEnvelope, FaFacebookF, FaInstagram} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      <a href="/#" className='footer_logo'>Priyanshu Khanda</a>
      <ul className='permalinks'>
        <li><a href="/#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        {/* <li><a href="#services">Services</a></li> */}
        <li><a href="#portfolio">Portfolio</a></li>
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>
      <div className="footer_socials">
        <a href="https://www.facebook.com/priyanshukhanda"><FaFacebookF/></a>
        <a href="https://www.instagram.com/priyanshukhanda/"><FaInstagram/></a>
        <a href="mailto:priyanshukhanda@gmail.com"><FaEnvelope/></a>
      </div>
      <div className="footer_copyright">
        <small>&copy; Priyanshu Khanda. All rights reserved.</small>
      </div>
    </footer>
  )
};

export default Footer;